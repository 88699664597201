import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect(){
    this.element.dataset['action'] = 'submit->disabler#disableForm'
  }

  disableForm() {
    this.element.querySelectorAll("input[type='submit']")
      .forEach(button => {
        button.disabled = true
        button.dataset.disablerRemoveClasses.split(" ")
          .forEach((klass) => button.classList.remove(klass))
        button.dataset.disablerAddClasses.split(" ")
          .forEach((klass) => button.classList.add(klass))
        button.value = "Syncing…"
      })
  }
}
